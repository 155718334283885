import { faCaretDown, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "../../Styles/applayout.module.css";
import { useLangContext } from "../../Store/langContext";
import langObj from "../../Utils/lang";

const SingleSideElement = ({ val, location, navigate }: any) => {
  console.log(val);
  const [open, setOpen] = useState(false);
  const [over, setOver] = useState(false);
  const { lang }: any = useLangContext();

  return (
    <>
      <li
        onClick={() => {
          if (val?.child) {
            setOpen((prev) => !prev);
          } else {
            navigate(val.path);
          }
        }}
        className={open && styles.menu_items_main}
        style={
          open
            ? { height: "fit-content", position: "relative" }
            : { height: "40px", overflow: "hidden", position: "relative" }
        }
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <div
          className={`${styles.menu_items_container_list_item} ${
            open && styles.sub_menu_head_open
          } ${val.path === location && styles.selected} `}
        >
          <val.icon />
          <span
            className={`${styles.sidebar_text} ${
              true && styles.sidebar_text_show
            }`}
            style={{ textTransform: "capitalize" }}
          >
            {langObj[val.title][lang]}
          </span>
          {val?.child && (
            <FontAwesomeIcon
              icon={faCaretDown}
              color={"grey"}
              className={styles.expand_icon}
              style={open ? { transform: "rotate(180deg)" } : {}}
            />
          )}

          {over && val?.add && (
            <FontAwesomeIcon
              className={styles.over_content}
              icon={faPlusCircle}
              color={over && val.path === location ? "white" : ""}
            />
          )}
        </div>
        {val?.child && (
          <div
            className={`${styles.menu_sub_items} ${
              open && styles.menu_sub_items_open
            }`}
          >
            {val?.child.map((v: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`${styles.menu_sub_items_single} ${
                    v.path === location && styles.menu_sub_single_selected
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(v.path);
                  }}
                >
                  <p style={{ textTransform: "capitalize" }}>
                    {langObj[v.title][lang] ? langObj[v.title][lang] : v.title}
                  </p>
                  {v?.add && (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={styles.over_sub_content}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        navigate(v.addUrl);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </li>
    </>
  );
};

export default SingleSideElement;
