import React, { useState, useEffect } from "react";
import styles from "../../Styles/content.module.css";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ImportIcon } from "../../Assets/newIcons/import.svg";
import { ReactComponent as ExportIcon } from "../../Assets/newIcons/export.svg";
import { ReactComponent as LogsIcon } from "../../Assets/newIcons/logs.svg";

const ContentHead = (props) => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const renderButton = (show, name, type, text, iconProps, style = {}) => {
    if (!show) return null;
    return (
      <Button
        name={name}
        type={type}
        text={text}
        style={{ marginRight: "10px", ...style }}
        clickHandler={props.onClickHandler}
        isIcon={iconProps?.isIcon || false}
        iconType={iconProps?.iconType}
      />
    );
  };

  const buttons = [
    {
      show: props.showBtn4,
      name: props.btn4Name,
      type: props.btn4type,
      text: props.btn4Text,
      iconProps: { isIcon: props.isIcon4, iconType: props.iconType4 },
    },
    {
      show: props.showBtn3,
      name: props.btn3Name,
      type: props.btn3type,
      text: props.btn3Text,
      iconProps: { isIcon: props.isIcon3, iconType: props.iconType3 },
    },
    {
      show: props.showBtn2,
      name: props.btn2Name,
      type: props.btn2type,
      text: props.btn2Text,
      iconProps: { isIcon: props.isIcon2, iconType: props.iconType2 },
    },
    {
      show: props.showStatusUpdateBtn,
      name: props.statusUpdateBtnName,
      type: props.statusUpdateBtnType,
      text: props.statusUpdateBtnText,
      iconProps: { isIcon: props.isIcon1, iconType: props.iconType1 },
    },
    {
      show: props.showReturnBtn,
      name: props.returnStatusBtnName,
      type: props.returnStatusBtnType,
      text: props.returnStatusBtnText,
      iconProps: { isIcon: props.isIcon, iconType: props.iconType },
    },
    {
      show: props.showRefundBtn,
      name: props.refundStatusBtnName,
      type: props.refundStatusBtnType,
      text: props.refundStatusBtnText,
      iconProps: { isIcon: props.isIcon, iconType: props.iconType },
    },
    {
      show: props.showBtn1,
      name: props.btn1Name,
      type: props.btn1type,
      text: props.btn1Text,
      iconProps: { isIcon: props.isIcon1, iconType: props.iconType1 },
    },
  ];

  const visibleButtons = buttons.filter((btn) => btn.show);

  return (
    <div className={styles.content_container}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          textWrap: "nowrap",
        }}
      >
        {props.title}
      </h1>
      <div style={{ flexGrow: 1 }}></div>
      {props.customElement}

      {isMobile ? (
        <div className={styles.more_menu}>
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            onClick={() => setOpen(!open)}
          />
          {open && (
            <>
              <ul className={styles.more_list}>
                {visibleButtons.map((btn, index) => (
                  <li
                    key={index}
                    className={styles.more_list_item}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onClickHandler({ target: { name: btn.name } });
                      setOpen(false);
                    }}
                  >
                    {btn.text}
                  </li>
                ))}
                {props.isMoreSettings &&
                  props.moreSettingsList?.map((v, i) => {
                    // const Icon = more_list_icons[v.icon];
                    return (
                      <li
                        key={`more-${i}`}
                        className={styles.more_list_item}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen(false);
                          props.handleMoreClick({ target: { name: v.name } });
                        }}
                      >
                        {/* <Icon /> */}
                        {v.title}
                      </li>
                    );
                  })}
              </ul>
              <div
                className={styles.more_cover}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              />
            </>
          )}
        </div>
      ) : (
        <>
          {visibleButtons.map((btn, index) =>
            renderButton(
              btn.show,
              btn.name,
              btn.type,
              btn.text,
              btn.iconProps,
              { order: visibleButtons.length - index }
            )
          )}
          {props.isMoreSettings && (
            <div className={styles.more_menu}>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                onClick={() => setOpen(!open)}
              />
              {open && (
                <>
                  <ul className={styles.more_list}>
                    {props.moreSettingsList?.map((v, i) => {
                      const Icon = more_list_icons[v.icon];
                      return (
                        <li
                          key={i}
                          className={styles.more_list_item}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                            props.handleMoreClick(v.name);
                          }}
                        >
                          <Icon />
                          {v.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div
                    className={styles.more_cover}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const more_list_icons = {
  import: ImportIcon,
  export: ExportIcon,
  logs: LogsIcon,
};

export default React.memo(ContentHead);
