export function convertDateTime(input) {
  // Parse the date and time
  const date = new Date(input);

  // Get the day, month, year, hour, and minute
  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour time
  hours = hours % 12 || 12;

  // Format the final output
  return `${day} ${month} ${year} ( ${hours}:${minutes} ${period})`;
}
