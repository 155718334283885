import React from "react";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import styles from "../../../Styles/orderDetails.module.css";
import InputField from "../../../Components/InputField";
import withRouter from "../../../Components/withRouter";
import axios from "axios";
import { toast } from "react-hot-toast";
import { ReactComponent as Cross } from "../../../Assets/icons/cross.svg";
import { ReactComponent as Tick } from "../../../Assets/icons/tick.svg";

type Props = {
  data: any;
  loading: boolean;
  fulfillmentStatus: string;
  cancelModal: boolean;
  cancelReturnStatus: string;
  cancelRefundStatus: string;
  user: any;
  product: any | null;
};

class ServiceOrderDetails extends React.Component<any, Props> {
  state = {
    data: {},
    loading: true,
    fulfillmentStatus: "",
    cancelModal: false,
    cancelReturnStatus: "",
    cancelRefundStatus: "",
    user: {},
    product: null,
  };

  componentDidMount() {
    this.getOrderDetails();
  }

  getOrderDetails = () => {
    const { id } = this.props.router.params;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}orders/` + id,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    }).then((res: any) => {
      if (Array.isArray(res.data?.orders)) {
        axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_BASE_URL}orders/increasing?identifier=` +
            localStorage.getItem("identifier") +
            "&page=1&limit=1000",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((response: any) => {
            let data = response.data.orders.filter(
              (val: any) => val._id === id
            )[0];

            this.setState({
              data: data,
              fulfillmentStatus: data.fulfilmentStatus,
            });

            this.getCustomerDetails(
              data.customerId,
              data?.serviceId ? data?.serviceId : data?.productId,
              data?.serviceId ? "service" : "product"
            );
          })
          .catch((error: any) => {});
      } else {
        let data = res.data.order;

        this.setState({
          data: data,
          fulfillmentStatus: data.fulfilmentStatus,
        });

        this.getCustomerDetails(
          data.customerId,
          data?.serviceId ? data?.serviceId : data?.productId,
          data?.serviceId ? "service" : "product"
        );
      }
    });
  };

  getCustomerDetails = (id: string, ID: string, type: string) => {
    axios({
      url: "https://backend.retaino.in/api/users/getuserbyid/" + id,
      method: "get",
    })
      .then((res: any) => {
        this.setState({ user: res.data.user });
        this.getDetails(type, ID);
      })
      .catch((err: any) => {});
  };

  getDetails = (type: string, ID: string) => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}service/` + ID,
      method: "GET",
    })
      .then((res: any) => {
        this.setState({ product: res.data.service, loading: false });
      })
      .catch((err: any) => {});
  };

  updateOrder = () => {
    this.setState({ loading: true });

    const { id } = this.props.router.params;
    const { fulfillmentStatus } = this.state;

    let data: any = {
      fulfilmentStatus: fulfillmentStatus,
    };

    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}orders/product/` + id,
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.router.navigate("/orders");
        toast.success("Order updated successfully.");
      })
      .catch((err) => {
        toast.error("Something went wrong.");
        this.setState({
          loading: false,
        });
      });
  };

  cancelOrder = () => {
    this.setState({ loading: true });

    const { id } = this.props.router.params;

    let data: any = {
      fulfilmentStatus: "cancelled",
      orderStatus: false,
    };

    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}orders/product/` + id,
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.router.navigate("/orders");
        toast.success("Order canceled successfully.");
      })
      .catch((err) => {
        toast.error("Something went wrong.");
        this.setState({
          loading: false,
        });
      });
  };

  setOrderStatusToComplete = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}orders/service/` +
        this.props.router.params.id,
      method: "patch",
      data: {
        identifier: localStorage.getItem("identifier"),
        fulfilmentStatus: "completed",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        if (res.data.message === "updated successfully") {
          toast.success("Order status updated successfully.");
          this.props.router.navigate("/orders");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      });
  };

  render(): React.ReactNode {
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        ) : (
          <Content>
            <ContentHead
              title={
                <>
                  Order Details {/* @ts-ignore */}
                  {this.state.fulfillmentStatus === "cancelled" && (
                    <span
                      style={{
                        color: "rgb(255, 30, 30)",
                        fontWeight: "400",
                        fontSize: "0.8rem",
                      }}
                    >
                      CANCELLED
                    </span>
                  )}
                  {this.state.fulfillmentStatus === "completed" && (
                    <span
                      style={{ color: "rgb(76, 175, 80)", fontWeight: "600" }}
                    >
                      COMPLETED
                    </span>
                  )}
                </>
              }
              showBtn1={
                this.state.fulfillmentStatus === "cancelled" ||
                this.state.fulfillmentStatus === "completed"
                  ? false
                  : true
              }
              showBtn2={
                this.state.fulfillmentStatus === "cancelled" ||
                this.state.fulfillmentStatus === "completed"
                  ? false
                  : true
              }
              showBtn3={this.state.fulfillmentStatus !== "cancelled"}
              btn1Text="Back"
              btn2Text={
                <div
                  className={styles.head_btns_container}
                  onClick={(e: any) => {
                    // propagate event to parent
                    e.stopPropagation();
                    // @ts-ignore
                    if (this.state.data.orderStatus) {
                    } else {
                      this.setOrderStatusToComplete();
                    }
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Tick
                    className={styles.head_btns_icon}
                    style={{ stroke: "white" }}
                  />{" "}
                  Mark as Completed
                </div>
              }
              btn3Text={
                <div
                  className={styles.head_btns_container}
                  onClick={(e: any) => {
                    // console.log(e.target);
                    // propagate event to parent
                    // e.stopPropagation();
                    this.setState({
                      cancelModal: true,
                      // cancelReturnStatus: "completed",
                      // cancelRefundStatus: "completed",
                    });
                    // this.props.router.navigate("/orders");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // zIndex: "-1",
                  }}
                >
                  <Cross
                    className={styles.head_btns_icon}
                    style={{ fill: "white", height: "20px", width: "20px" }}
                  />
                  Cancel Order
                </div>
              }
              btn1Name="btn1"
              btn2Name="btn2"
              btn3Name="btn3"
              // btn1type="filled_green"
              btn1type="light_grey"
              btn2type="filled_green"
              btn3type="filled_red"
              onClickHandler={(event: any) => {
                console.log(event.target.name);
                if (event.target.name === "btn1") {
                  console.log(event.target.name);
                  this.props.router.navigate("/orders");
                } else if (event.target.name === "btn2") {
                  // @ts-ignore
                  if (this.state.data.orderStatus) {
                  } else {
                    this.setOrderStatusToComplete();
                  }
                } else if (event.target.name === "btn3") {
                  // console.log(event.target.name);
                  // this.props.router.navigate("/orders");
                  // this.setState({
                  //   cancelModal: true,
                  //   cancelReturnStatus: "completed",
                  //   cancelRefundStatus: "completed",
                  // });
                }
              }}
            />
            <ContentBody>
              <div className={styles.wrapper}>
                {/* @ts-ignore */}
                {this.state.data?.productId && (
                  <div className={styles.container}>
                    <h2>Product</h2>
                    <div className={styles.product_details}>
                      {/* product image */}
                      <div className={styles.product_image}>
                        <img
                          src={
                            process.env.REACT_APP_FAST_LINK +
                            "/" +
                            localStorage.getItem("identifier")?.split(".")[0] +
                            "/" +
                            // @ts-ignore
                            // this.state.data.productId.mediaUrl[0]
                            ""
                          }
                          alt=""
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      {/* product details */}
                      <div
                        className={styles.details}
                        style={{ paddingInline: 0, marginTop: 0 }}
                      >
                        <div className={styles.about_product}>
                          <div
                            style={{
                              height: "fit-content",
                              lineHeight: ".9rem",
                            }}
                          >
                            <span
                              style={{
                                fontSize: ".8rem",
                              }}
                            >
                              {/* @ts-ignore */}
                              {this.state.data.productId?.category}
                            </span>
                            <h3 style={{ lineHeight: "1rem" }}>
                              {/* @ts-ignore */}
                              {this.state.data.productId?.title}
                            </h3>
                          </div>
                          <p>
                            Price :{" "}
                            <span>
                              {/* @ts-ignore */}₹
                              {this.state.data.productId?.pricing.price}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            {/* @ts-ignore */}
                            Quantity : <span>{this.state.data.quantity}</span>
                          </p>
                          <div className={styles.variants}>
                            <p>Variant :</p>
                            <ul>
                              {/* @ts-ignore */}
                              {this.state.data.variant.map(
                                (val: any, index: any) => {
                                  return (
                                    <li key={index}>
                                      {val.options_name} : {val.options_value}
                                    </li>
                                  );
                                }
                              )}
                            </ul>

                            <p>
                              Total Price : {/* @ts-ignore */}
                              <span>₹{this.state.data.total}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* @ts-ignore */}
                {this.state.data?.serviceId && (
                  <div className={styles.container}>
                    <h2
                      style={{
                        color: "grey",
                        fontSize: "26px",
                        paddingBottom: "13px",
                      }}
                    >
                      {/* @ts-ignore */}
                      {/* @ts-ignore */} #{this.state.data.orderRefId}
                    </h2>
                    <h2>Service Details</h2>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        paddingInline: "15px",
                        paddingTop: "10px",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Service Title :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.product?.title}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>Location : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.product?.location}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Sub Location :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.product?.subLocation}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>Category : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.product?.category}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Sub Category :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.product?.subCategory}
                        </span>
                      </div>
                    </div>

                    <h2 style={{ marginTop: "25px" }}>Booking Details</h2>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        paddingInline: "15px",
                        paddingTop: "10px",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: "600" }}>Date : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.date
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>Start Time : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.startTime
                            .split("T")[1]
                            .split(":")
                            .splice(0, 2)
                            .join(":")}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>End Time : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.endTime
                            .split("T")[1]
                            .split(":")
                            .splice(0, 2)
                            .join(":")}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Discount Used :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.discount === "" ? "None" : ""}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>Sub Total : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.subTotal.toFixed(2)}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>Tax : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.tax.toFixed(2)}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>Total : </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.total.toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <h2 style={{ marginTop: "25px" }}>Payment Details</h2>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        paddingInline: "15px",
                        paddingTop: "10px",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Mode Of Payment :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          <span>
                            {/* @ts-ignore */}
                            {this.state.data?.modeOfPayment === "pg" &&
                              "Online Payment"}
                            {/* @ts-ignore */}
                            {this.state.data?.modeOfPayment === "cod" &&
                              "Pay At Venue"}
                          </span>
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>
                          {/* @ts-ignore */}
                          Payment Status : {this.state.data?.paymentStatus}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.paymentStatus === "unpaid" &&
                            "Un-Paid"}
                          {/* @ts-ignore */}
                          {this.state.data?.paymentStatus === "paid" && "Paid"}
                          {/* @ts-ignore */}
                          {this.state.data?.paymentStatus === "failed" &&
                            "Failed"}
                          {/* @ts-ignore */}
                          {this.state.data?.paymentStatus === "partial" &&
                            "Partial"}
                        </span>
                      </div>

                      <div></div>

                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Paid Amount :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.paymentDetails.length === 0
                            ? // @ts-ignore
                              this.state.data?.total
                            : // @ts-ignore
                              this.state.data?.paymentDetails
                                .filter((val: any) => val.status)
                                .reduce(
                                  (total: any, num: any) =>
                                    total + num.paidAmount,
                                  0
                                )}
                        </span>
                      </div>

                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Remaining Amount :{" "}
                        </span>

                        <span style={{ textTransform: "capitalize" }}>
                          {/* @ts-ignore */}
                          {this.state.data?.paymentDetails.length === 0
                            ? 0
                            : // @ts-ignore
                              this.state.data.total -
                              // @ts-ignore
                              this.state.data?.paymentDetails
                                .filter((val: any) => val.status)
                                .reduce(
                                  (total: any, num: any) =>
                                    total + num.paidAmount,
                                  0
                                )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className={styles.container}>
                  <h2>Customer</h2>
                  <div className={styles.details}>
                    <div className={styles.details_1}>
                      <p>
                        First Name : {/* @ts-ignore */}
                        <span>{this.state.user?.firstName}</span>
                      </p>
                      <p>
                        Last Name : {/* @ts-ignore */}
                        <span>{this.state.user?.lastName}</span>
                      </p>
                    </div>
                    <div className={styles.details_1}>
                      <p>
                        Email :{" "}
                        <span>
                          {/* @ts-ignore */}
                          {this.state.user?.email}
                        </span>
                      </p>
                      {/* @ts-ignore */}
                      {this.state.user?.mobile?.number && (
                        <p>
                          Phone Number :{" "}
                          <span>
                            {/* @ts-ignore */}
                            +91-{this.state.user?.mobile?.number}
                          </span>
                        </p>
                      )}

                      <p>
                        Billing Address :{" "}
                        <span>
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["name"]},{" "}
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["addLineOne"]}{" "}
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["addLineTwo"]}{" "}
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["landmark"]}{" "}
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["city"]}{" "}
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["state"]} -{" "}
                          {/* @ts-ignore */}
                          {this.state.data.billingAddress["pincode"]}
                        </span>
                      </p>
                      {/* @ts-ignore */}
                      {!this.state.data?.serviceId && (
                        <p>
                          Shipping Address :{" "}
                          <span>
                            {/* @ts-ignore */}
                            {/* {this.state.data?.shippingAddress["name"]},{" "} */}
                            {/* @ts-ignore */}
                            {/* {this.state.data.shippingAddress["addLineOne"]}{" "} */}
                            {/* @ts-ignore */}
                            {/* {this.state.data.shippingAddress["addLineTwo"]}{" "} */}
                            {/* @ts-ignore */}
                            {/* {this.state.data.shippingAddress["landmark"]}{" "} */}
                            {/* @ts-ignore */}
                            {/* {this.state.data.shippingAddress["city"]}{" "} */}
                            {/* @ts-ignore */}
                            {/* {this.state.data.shippingAddress["state"]} -{" "} */}
                            {/* @ts-ignore */}
                            {/* {this.state.data.shippingAddress["pincode"]} */}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </ContentBody>
          </Content>
        )}

        {!!this.state.cancelModal && (
          <div className={styles.cancel_modal_container}>
            <div className={styles.cancel_card}>
              <h1>Cancel Order</h1>
              <p>
                Are you sure about this action ? user will see their status as :{" "}
              </p>
              {/* @ts-ignore */}
              {(this.state.data.modeOfPayment === "upi1" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "upi2" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "credit" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "debit" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "netbanking" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "emi" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "wallet") && (
                <div className={styles.cancel_fields_container}>
                  <InputField
                    type="select"
                    isLabel={true}
                    lable={"Refund Status"}
                    value={this.state.cancelRefundStatus}
                    onChange={(e: any) => {
                      this.setState({ cancelRefundStatus: e.target.value });
                    }}
                    options={[
                      { title: "Request", value: "requested" },
                      { title: "Processing", value: "processing" },
                      { title: "Completed", value: "completed" },
                    ]}
                    accessText="title"
                    accessValue="value"
                  />
                </div>
              )}
              <div className={styles.cancel_btns}>
                <button onClick={() => this.setState({ cancelModal: false })}>
                  Discard
                </button>
                <button onClick={this.cancelOrder}>Cancel Order</button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(ServiceOrderDetails);
