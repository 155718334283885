import React, { forwardRef } from "react";
import styles from "./invoice.module.css";
import { numberToWords } from "../../Utils/numberToWordsConverter";
import SubAdminPanel from "../Settings/subAdminPanel/SubAdminPanel";

const Invoice = forwardRef((props: any, ref: any) => {
  let { user, order, admin } = props;

  if (!Object.keys(order)?.length) {
    return <></>;
  }

  const calculateAmt = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .
    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  const calculateTaxAmount = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ): string => {
    if (isInclusive) {
      return (subTotal - (subTotal / 1 + gst / 100)).toFixed(2);
    }
    // this will return the price after adding tax .
    return ((gst / 100) * subTotal).toFixed(2);
  };

  const totalIGSTAmount = () => {
    const totaligst = order?.products?.reduce((acc: any, product: any) => {
      const taxAmount = calculateTaxAmount(
        product?.productId?.pricing?.price,
        product?.productId?.pricing?.inclusiveOfGST,
        product?.productId?.pricing?.igst
      );
      const taxAmountWithQuantity = Number(taxAmount) * product.quantity;
      return acc + taxAmountWithQuantity;
    }, 0);
    return totaligst;
  };
  //  order = order[0];
  return (
    <div className={styles.invoice} ref={ref}>
      <style type="text/css" media="print">
        {
          "\
  @page { size: 220mm 297mm; }\
"
        }
      </style>
      <div className={styles.inner_container}>
        <div className={styles.header}>
          <img src={admin?.logoUrl} alt="logo not found" />
          <div className={styles.companyDetails}>
            <h2>{admin.address?.companyName}</h2>
            <p>{admin?.address?.addLineOne}</p>
            <p>{admin?.address?.addLineTwo}</p>

            <p>{admin?.address?.landmark} </p>
            <p> {admin?.address?.city}</p>

            <p>{admin.address?.companyStateZip}</p>
            <p>{admin.address?.companyCountry}</p>
          </div>
          <div className={styles.invoiceTitle}>
            <h1>TAX INVOICE</h1>
          </div>
        </div>

        <div className={styles.invoiceDetails}>
          <div>
            <p>
              {" "}
              <strong> Order Number: </strong>
              {order.orderRefId}
            </p>
            <p>
              <strong> Order Date: </strong>

              {order?.date}
            </p>
            <p>
              <strong>Invoice Date:</strong>{" "}
              {new Date().toLocaleDateString("en-GB")}
            </p>
          </div>
        </div>
        <div className={styles.customerDetails}>
          <h5>
            <strong>Customer Details</strong>
          </h5>
          <div>
            <p>{order.billingAddress?.name}</p>
            <p>{order.billingAddress?.addLineOne}</p>
            <p> {order.billingAddress?.addLineTwo}</p>
            <p>{order.billingAddress?.landmark}</p>
            <p>
              {order.billingAddress?.city}, {order.billingAddress?.state},
              {order.billingAddress?.pincode}
            </p>
          </div>
        </div>

        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th style={{ textAlign: "left" }}>Item & Description</th>
                <th>Qty.</th>
                <th>Rate</th>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  IGST
                </th>
                <th>Amount</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>%</th>
                <th>Amt</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {order.products?.map((item: any, ind: any) => {
                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td style={{ textAlign: "left" }}>
                      {item.productId?.title}
                    </td>
                    <td>{item?.quantity || "NA"}</td>
                    <td>
                      ₹{" "}
                      {item?.productId?.pricing?.inclusiveOfGST
                        ? calculateAmt(
                            item?.productId?.pricing?.price,
                            item?.productId?.pricing?.inclusiveOfGST,
                            item?.productId.pricing?.igst
                          )
                        : item?.productId?.pricing?.price}
                    </td>
                    <td>
                      {item?.productId?.pricing?.igst}%
                      {/* {item?.productId?.pricing?.igst === 0
                        ? ""
                        : item?.productId?.pricing?.inclusiveOfGST
                        ? "(inc.)"
                        : "(exc.)"}{" "} */}
                    </td>
                    <td>
                      ₹{" "}
                      {calculateTaxAmount(
                        item?.productId?.pricing?.price,
                        item?.productId?.pricing?.inclusiveOfGST,
                        item?.productId?.pricing?.igst
                      )}
                    </td>
                    <td>
                      ₹{" "}
                      {item?.quantity *
                        Number(
                          calculateAmt(
                            item?.productId?.pricing?.price,
                            item?.productId?.pricing?.inclusiveOfGST,
                            item?.productId?.pricing?.igst
                          )
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={styles.summary}>
          <div className={styles.amountInWords}>
            <p>
              <strong>Total In Words:</strong>
              <br /> {numberToWords(Number(order.total))}
            </p>
          </div>
          <div className={styles.totalAmount}>
            <p>
              <strong>Sub Total:</strong>₹ {order?.subTotal}
            </p>
            {order?.shippingCharges?.subTotal && (
              <p>
                <strong>Shipping Charge:</strong>₹{" "}
                {order?.shippingCharges?.subTotal}
              </p>
            )}
            {order?.discount?.length !== 0 && (
              <p>
                <strong>Discount:</strong>- ₹{" "}
                {order?.discount[0]?.totalDiscount || 0}
              </p>
            )}
            <p>
              <strong>Total:</strong>₹ {order?.total}
            </p>
          </div>
        </div>

        {admin?.bankDetails?.bankName && (
          <div className={styles.footer}>
            <p>
              <strong>Bank Name:</strong> {admin?.bankDetails?.bankName}
            </p>
            {admin?.bankDetails?.branch && (
              <p>
                <strong>Branch Name:</strong>
                {admin?.bankDetails?.branch}
              </p>
            )}
            {admin?.bankDetails?.accountNumber && (
              <p>
                <strong>Account Number:</strong>{" "}
                {admin?.bankDetails?.accountNumber}
              </p>
            )}
            {admin?.bankDetails?.ifsc && (
              <p>
                <strong>IFSC:</strong> {admin?.bankDetails?.ifsc}
              </p>
            )}
            {admin?.bankDetails?.UPI && (
              <p>
                <strong>UPI:</strong> {admin?.bankDetails?.UPI}
              </p>
            )}
            <p style={{ marginTop: "10px" }}>
              <em>
                We declare that this invoice shows the actual price of the
                service described and that all the particulars are true and
                correct.
              </em>
            </p>
          </div>
        )}
        <div
          className={styles.table}
          style={{
            pageBreakBefore: order.products?.length >= 5 ? "always" : "unset",
          }}
        >
          <table>
            <thead>
              <tr>
                {/* <th>#</th> */}
                <th style={{ textAlign: "left" }}>HSN/SAC</th>

                <th>Taxable Amount</th>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  IGST
                </th>
                <th>Total Tax Amount</th>
              </tr>
              <tr>
                {/* <th></th> */}

                <th></th>
                <th></th>
                <th>%</th>
                <th>Amt</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {order.products?.map((item: any, ind: any) => {
                return (
                  <tr key={ind}>
                    {/* <td>{ind + 1}</td> */}

                    <td>{item?.productId?.hsnsac || "NA"}</td>
                    <td>
                      ₹
                      {item.productId?.pricing?.inclusiveOfGST
                        ? item?.quantity *
                          Number(
                            calculateAmt(
                              item?.productId?.pricing?.price,
                              item?.productId?.pricing?.inclusiveOfGST,
                              item?.productId.pricing?.igst
                            )
                          )
                        : item?.quantity * item?.productId?.pricing?.price}
                    </td>
                    <td>{item?.productId?.pricing?.igst}%</td>
                    <td>
                      ₹
                      {item?.quantity *
                        Number(
                          calculateTaxAmount(
                            item?.productId?.pricing?.price,
                            item?.productId?.pricing?.inclusiveOfGST,
                            item?.productId?.pricing?.igst
                          )
                        )}
                    </td>
                    <td>
                      ₹{" "}
                      {item?.quantity *
                        Number(
                          calculateTaxAmount(
                            item?.productId?.pricing?.price,
                            item?.productId?.pricing?.inclusiveOfGST,
                            item?.productId?.pricing?.igst
                          )
                        )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                {/* <td>{ind + 1}</td> */}

                <td>Total</td>
                <td>₹{order.subTotal}</td>
                {/* <td></td> */}
                <td colSpan={2}>₹{totalIGSTAmount()}</td>
                <td>₹{totalIGSTAmount()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        POWERED BY
        <img src="/logo.png" alt="" style={{ width: "40px", height: "auto" }} />
      </div>
    </div>
  );
});

export default Invoice;
