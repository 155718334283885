import React from "react";
import styles from "../viewEvents.module.css";
import { convertDateTime } from "../../../Utils/getDateTime";

const EventDetails = ({ data }) => {
  return (
    <div>
      <div className={styles.image_section}>
        <img src={data?.mediaUrl[0]} alt="" />
      </div>
      <div className={styles.secondary_content}>
        <div>
          <span>Location</span>
          <p>{data?.location || "--"}</p>
        </div>

        <div>
          <span>Starting At</span>
          <p>{convertDateTime(data?.operationHours[0]?.startTime) || "--"}</p>
        </div>
        <div>
          <span>Ending At</span>
          <p>{convertDateTime(data?.operationHours[0]?.endTime) || "--"}</p>
        </div>
        <div>
          <span>Pricing</span>
          <p>{data?.pricing?.basePricing || "Free"}</p>
        </div>

        <div>
          <span>Seats</span>
          <p>{data?.maxQuantity || "Unlimited"}</p>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
