import React from "react";
import styles from "../viewEvents.module.css";
import { MdRemoveRedEye } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const users = [
  {
    id: 1,
    name: "Cody Fisher",
    email: "cody@example.com",
    phoneNumber: "123-456-7890",
    date: "11 Feb 2024",
    paymentMethod: "Card",
    status: "Approved",
  },
  {
    id: 2,
    name: "Jane Doe",
    email: "jane@example.com",
    phoneNumber: "987-654-3210",
    date: "12 Feb 2024",
    paymentMethod: "PayPal",
    status: "Pending",
  },
  // Add more data as needed
];

const EventUsers = () => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Sl</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Date</th>
              <th>Payment Method</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.date}</td>
                <td>{user.paymentMethod}</td>
                <td>
                  <span
                    className={`${styles.status} ${
                      user.status === "Approved" ? styles.approved : ""
                    }`}
                  >
                    {user.status}
                  </span>
                </td>
                <td>
                  <button className={styles.iconButton}>
                    <MdRemoveRedEye />
                  </button>
                  <button className={styles.iconButton}>
                    <FaEdit />
                  </button>
                  <button className={styles.iconButton}>
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventUsers;
