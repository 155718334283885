import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import ObjToExcel from "../../Utils/objToExcel";
import { useLocation, useNavigate } from "react-router-dom";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "./events.module.css";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faPlus,
  faSearch,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as TrashIcon } from "../../Assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../Assets/newIcons/edit.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
let inFocus = "";

const Events = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang }: any = useLangContext();
  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState({});

  const [contacts, setContacts]: any = useState([]);
  const [updatedContacts, setUpdatedContacts]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [subAdmins, setSubAdmins] = useState([]);

  const [updateMode, setUpdateMode] = useState(false);

  const [remark, setRemark] = useState("");
  const [logs, setLogs] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [disableForAction, setDisableForAction] = useState(false);

  const [filter, setFilter]: any = useState({
    contactOwner: [],
    createDate: "",
    lastActivityDate: "",
    leadStatus: [],
    search: "",
  });

  const [deleteById, setDeleteById] = useState("");

  const { admin }: any = useAdminContext();

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}sub-admin`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setSubAdmins(res.data.sub_admins);
      })
      .catch((err: any) => {});
  }, []);

  useEffect(() => {
    handlePage();
  }, [filter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter((prevFilter: any) => ({ ...prevFilter, search: searchTerm }));
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (contacts.length === 0) return;

    if (contacts.filter((val: any) => val.actions.select).length !== 0) {
      setDisableForAction(true);
    } else {
      setDisableForAction(false);
    }
  }, [contacts]);

  useEffect(() => {
    setUpdateMode(updatedContacts.length === 0 ? false : true);
  }, [updatedContacts]);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    setLoading(true);

    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}service/?identifier=` +
        localStorage.getItem("identifier"),
      method: "get",
    })
      .then((res) => {
        console.log(res);
        setContacts([
          ...res.data.services.map((val: any) => {
            if (val?.modifiedAt) {
              const utc = new Date(val?.modifiedAt);
              const utc_createdAt = new Date(val?.createdAt);

              const local = new Date(
                utc.toLocaleString("en-us", { timeZone: "Asia/Kolkata" })
              );

              const local_createdAt = new Date(
                utc_createdAt.toLocaleString("en-us", {
                  timeZone: "Asia/Kolkata",
                })
              );

              const formattedLocal = `${local.getFullYear()}-${String(
                local.getMonth() + 1
              ).padStart(2, "0")}-${String(local.getDate()).padStart(
                2,
                "0"
              )}T${String(local.getHours()).padStart(2, "0")}:${String(
                local.getMinutes()
              ).padStart(2, "0")}`;

              const formattedLocal_createdAt = `${local_createdAt.getFullYear()}-${String(
                local_createdAt.getMonth() + 1
              ).padStart(2, "0")}-${String(local_createdAt.getDate()).padStart(
                2,
                "0"
              )}T${String(local_createdAt.getHours()).padStart(
                2,
                "0"
              )}:${String(local_createdAt.getMinutes()).padStart(2, "0")}`;

              if (inView._id === val._id) {
                setInView({
                  ...val,
                  modifiedAt: formattedLocal,
                  createdAt: formattedLocal_createdAt,
                  actions: { select: false },
                });
              }

              return {
                ...val,
                modifiedAt: formattedLocal,
                createdAt: formattedLocal_createdAt,
                actions: { select: false },
              };
            } else {
              if (inView._id === val._id) {
                setInView({
                  ...val,
                  actions: { select: false },
                });
              }
              return {
                ...val,
                actions: { select: false },
              };
            }
          }),
        ]);
        // setTotal(res.data.totalContacts);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  // console.log(inView);
  // console.log(contacts);

  const handleDelete = async () => {
    setModal(false);
    setLoading(true);

    const temp = [...contacts]
      .filter((val) => val.actions.select)
      .map((v) => v._id);

    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}contacts/multiple-delete`,
      data: { ids: temp },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    });

    getData();
    setLoading(false);
    toast.success("Deleted Successfully.");
  };

  const handleSingleDelete = () => {
    setModal(false);
    setLoading(true);

    let index: any;

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i]._id === deleteById) {
        index = i;
        break;
      }
    }

    let nextInView: any;

    if (index === 0) {
      nextInView = contacts[1];
    } else if (contacts.length - 1 === index) {
      nextInView = contacts[index - 1];
    } else {
      nextInView = contacts[index - 1];
    }

    axios({
      url: `${process.env.REACT_APP_BASE_URL}contacts/` + deleteById,
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        getData();
        setLoading(false);
        setInView(nextInView);
        toast.success("Deleted Successfully.");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong while delete the contact.");
      });
  };

  const exportExcel = () => {
    ObjToExcel(contacts, "Leads", "Leads", [
      { title: "Name", value: "firstName" },
      { title: "Middle Name", value: "middleName" },
      { title: "Last Name", value: "lastName" },
      { title: "Mobile Number", value: "mobileNumber" },
      { title: "Email", value: "personalEmail" },
      { title: "Primary Company", value: "companyName" },
      { title: "Contact Owner", value: "leadOwner" },
      { title: "Lead Status", value: "status" },
      { title: "Created At", value: "createdAt" },
      { title: "Modified At", value: "modifiedAt" },
    ]);
  };

  const checkAll = () => {
    setContacts((prev: any) => {
      let temp = [...prev];
      if (
        temp.filter((val: any) => val.actions.select).length === temp.length
      ) {
        temp = temp.map((val) => ({ ...val, actions: { select: false } }));
      } else {
        temp = temp.map((val) => ({ ...val, actions: { select: true } }));
      }
      return temp;
    });
  };

  const changeSelect = (id: any) => {
    setContacts((prev: any) => {
      let temp: any = [...prev];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]._id === id) {
          temp[i].actions.select = !temp[i].actions.select;
          break;
        }
      }
      return temp;
    });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          inFocus = "";
          setDeleteById("");
        }}
        onBtn2Click={() => {
          if (deleteById) {
            handleSingleDelete();
          } else {
            handleDelete();
          }
        }}
        state={modal}
      />
      <Content>
        <ContentHead
          title={"Events"}
          showBtn1={true}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={true}
          moreSettingsList={[
            { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
            { icon: "logs", title: "Logs", name: "logs" },
          ]}
          handleMoreClick={(name: any) => {
            if (name === "import") {
              navigate("/contacts/import-file");
            } else if (name === "export") {
              exportExcel();
            } else if (name === "logs") {
              navigate("/contacts/logs");
            }
          }}
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              navigate("/events/create_event");
            }
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {secondaryView ? (
              <div className={styles.secondary_view}>
                <div className={styles.secondary_list_view}>
                  {contacts.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => {
                          setInView(val);
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={val.actions.select}
                          onClick={() => {
                            changeSelect(val._id);
                          }}
                        />
                        <div>
                          <p>{val?.title || "--"}</p>
                          <span>{val?.location}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.secondary_detail_view}>
                  <div>
                    <div className={styles.sdv_head}>
                      <p style={{ textTransform: "capitalize" }}>
                        {inView?.title || "--"}
                      </p>
                      <div className={styles.sdv_buttons}>
                        <button
                          onClick={() => navigate("/events/" + inView?._id)}
                        >
                          View
                        </button>
                        <button
                          onClick={() =>
                            navigate("/events/update_event/" + inView?._id)
                          }
                        >
                          <EditIcon /> Edit
                        </button>

                        <button
                          onClick={() => {
                            setDeleteById(inView?._id);
                            setModal(true);
                          }}
                        >
                          <TrashIcon />
                          Delete
                        </button>
                        <CloseIcon
                          className={styles.sdv_close_btn}
                          onClick={() => {
                            setSecondaryView(false);
                            setInView({});
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <>
                    <div className={styles.secondary_content}>
                      <div>
                        <span>Location</span>
                        <p>{inView?.location || "--"}</p>
                      </div>

                      <div>
                        <span>Starting At</span>
                        <p>{inView?.operationHours[0]?.startTime || "--"}</p>
                      </div>
                      <div>
                        <span>Ending At</span>
                        <p>{inView?.operationHours[0]?.endTime || "--"}</p>
                      </div>
                      <div>
                        <span>Pricing</span>
                        <p>{inView?.pricing?.basePricing || "Free"}</p>
                      </div>

                      <div>
                        <span>Seats</span>
                        <p>{inView?.maxQuantity || "Unlimited"}</p>
                      </div>
                      <div>
                        <span>Alt. Mobile Number</span>
                        <p>{inView?.altMobileNumber || "--"}</p>
                      </div>
                      <div>
                        <span>Email</span>
                        <p>{inView?.personalEmail || "--"}</p>
                      </div>
                      <div>
                        <span>Cooperate Email</span>
                        <p>{inView?.cooperateEmail || "--"}</p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ) : (
              <div className={styles.table_container}>
                <table className={styles.responsive_table}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={checkAll}
                          checked={
                            contacts.filter((val: any) => val.actions.select)
                              .length === contacts.length
                          }
                        />
                      </th>
                      <th>Event Title</th>
                      <th>Location</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Price</th>
                      <th>Total Seats</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts.map((val: any, index: any) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            setInView(val);
                            setSecondaryView(true);
                          }}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={val.actions.select}
                              onClick={(e) => {
                                e.stopPropagation();
                                changeSelect(val._id);
                              }}
                            />
                          </td>
                          <td>{val.title || "--"}</td>
                          <td>{val.location || "--"}</td>
                          <td>{val?.operationHours[0]?.startTime || "--"}</td>
                          <td>{val?.operationHours[0]?.endTime || "--"}</td>
                          <td>{val?.pricing.basePrice}</td>
                          {/* <td>{val.companyName || "--"}</td> */}
                          <td>{val.maxQuantity}</td>

                          {/* <td>
                            {val?.createdAt
                              ?.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")}{" "}
                            {val?.createdAt
                              ?.split("T")[1]
                              .split(":")
                              .slice(0, 2)
                              .join(":")}
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default Events;
