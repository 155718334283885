import React, { useEffect, useRef, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import langObj from "../../Utils/lang";
import TextEditor from "../../Components/TextEditor";
import InputField from "../../Components/InputField";
import ContentBody from "../../Components/Content/ContentBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ImageFolder from "../../Components/ImageFolder";
import Info from "../../Components/info";
import styles from "./events.module.css";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getFileName } from "../../Utils/getFileName";
import { uploadBulkImages } from "../../Apis/callApi";
import axios from "axios";
import toast from "react-hot-toast";

const CreateEvent = ({ id }: { id?: string }) => {
  // console.log(id);
  const [loading, setLoading] = useState(false);
  const [showCapacity, setShowCapacity] = useState(false);
  const placeChangeRef = useRef();
  const [query, setQuery] = useState("");
  const [showPrice, setShowPrice] = useState(false);

  const { lang }: any = useLangContext();
  const { admin }: any = useAdminContext();
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState({
    metaTitle: "",
    metaDescription: "",
  });

  let autoComplete: any;

  const loadScript = (url: string, callback: any) => {
    const script: any = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    console.log(script.readyState);
    if (script.readyState) {
      console.log("ready");
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.addEventListener("load", () => {
        console.log("loaded");
        callback();
      });
      // console.log(script.onload);
      // script.onload = () => {
      //   console.log("loaded");
      //   callback();
      // };
    }
    script.onerror = () => {
      console.error(`Failed to load script: ${url}`);
    };

    document.getElementsByTagName("head")[0].appendChild(script);
  };
  useEffect(() => {
    console.log(process.env.REACT_APP_GOOGLE_API_KEY);
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyB1mF9D6SwTvHzIF7wWcEgTOrOXQqCvrQ0&libraries=places`,
      () => handleScriptLoad(setQuery, placeChangeRef)
    );
  }, []);

  const handleScriptLoad = (setQuery: any, placeChangeRef: any) => {
    console.log("loaded");
    // @ts-ignore
    console.log(window.google);
    // @ts-ignore
    autoComplete = new window.google.maps.places.Autocomplete(
      placeChangeRef.current,
      {}
    );

    autoComplete.addListener("place_changed", () => {
      console.log("place_changed");
      handlePlaceSelect(setQuery);
    });
  };

  const handlePlaceSelect = (setQuery: any) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    setQuery(query);

    // Update location in eventDetails
    setEventDetails((prev: any) => ({
      ...prev,
      location: query,
    }));
  };

  const [eventDetails, setEventDetails] = useState({
    title: "",
    startDate: "",
    endDate: "",
    location: "",
    description: "",
    ticketPrice: 0,
    capacity: 0,
    approval: false,
    mediaUrl: [],
  });
  // console.log(eventDetails?.mediaUrl);

  useEffect(() => {
    if (id) getData();
  }, []);

  function getData() {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}service/` + id,
      method: "get",
    })
      .then((res) => {
        const response = res.data.service;
        // console.log(response);
        setEventDetails({
          title: response.title,
          startDate: response.operationHours[0].startTime,
          endDate: response.operationHours[0].endTime,
          location: response.location,
          description: response.description,
          ticketPrice: response?.pricing.basePrice,
          capacity: response.maxQuantity,
          approval: false,
          mediaUrl: response.mediaUrl,
        });
        setQuery(response.location);
        setMetaData({
          metaTitle: response?.seListing?.title,
          metaDescription: response?.seListing?.metalDescription,
        });
      })
      .catch((err) => {});
  }

  const handleSubmit = (event: any) => {
    if (event?.target.name === "btn3") {
      navigate(-1);
    } else if (event?.target.name === "btn1") {
      handleCreateAndUpdate();
    }
  };

  const handleCreateAndUpdate = async () => {
    console.log("event details", eventDetails?.location);
    let body: any = {
      identifier: localStorage.getItem("identifier"),
      title: eventDetails.title,
      description: eventDetails.description,
      location: eventDetails?.location,
      mediaUrl: eventDetails?.mediaUrl,
      maxQuantity: eventDetails?.capacity,
      pricing: { basePrice: eventDetails?.ticketPrice },
      operationHours: {
        startTime: eventDetails?.startDate,
        endTime: eventDetails?.endDate,
      },
      seListing: {
        title: metaData.metaTitle,
        metalDescription: metaData.metaDescription,
        routeHandle: "",
      },
    };

    axios({
      method: id ? "PATCH" : "POST",
      url: id
        ? `${process.env.REACT_APP_BASE_URL}service/` + id
        : `${process.env.REACT_APP_BASE_URL}service`,
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        navigate("/events");
        toast.success("Event Added Successfully.");
      })
      .catch((err: any) => {
        toast.error("Something went wrong, while adding a event");
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={id ? langObj.updateEvent[lang] : langObj.addEvent[lang]}
          showBtn1={
            admin.role === "subadmin" ? admin.caseStudy_access.write : true
          }
          showBtn2={
            admin.role === "subadmin" ? admin.caseStudy_access.write : true
          }
          showBtn3={true}
          btn1type="filled"
          btn2type="outlined"
          btn3type="outlined_del"
          btn1Name="btn1"
          btn2Name="btn2"
          btn3Name="btn3"
          btn1Text={langObj.save[lang]}
          btn2Text={langObj.saveAsDraft[lang]}
          btn3Text={langObj.cancel[lang]}
          onClickHandler={handleSubmit}
        />
        <ContentBody>
          <>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                isLabel={true}
                value={eventDetails?.title}
                lable={langObj.eventTitle[lang]}
                type="text"
                isRequired={false}
                placeholder=""
                onChange={(event: any) => {
                  setEventDetails((prev: any) => ({
                    ...prev,
                    title: event.target.value,
                  }));
                }}
              />
              <div className={styles.location_input_container}>
                <p>{langObj.location[lang]}</p>
                <input
                  ref={placeChangeRef}
                  type="text"
                  value={query}
                  placeholder={langObj.location[lang]}
                  onChange={(event: any) => {
                    // setEventDetails((prev: any) => ({
                    //   ...prev,
                    //   location: event.target.value,
                    // }));
                    setQuery(event.target.value);
                  }}
                />
              </div>
              <InputField
                type="datetime-local"
                isLabel={true}
                value={eventDetails?.startDate}
                lable={langObj.startingAt[lang]}
                isRequired={false}
                placeholder={langObj.startDate[lang]}
                onChange={(event: any) => {
                  setEventDetails((prev: any) => ({
                    ...prev,
                    startDate: event.target.value,
                  }));
                }}
              />
              <InputField
                type="datetime-local"
                isLabel={true}
                value={eventDetails?.endDate}
                lable={langObj.endingAt[lang]}
                isRequired={false}
                placeholder={langObj.endDate[lang]}
                onChange={(event: any) => {
                  setEventDetails((prev: any) => ({
                    ...prev,
                    endDate: event.target.value,
                  }));
                }}
              />
            </div>
            <div className="grid_2" style={{ marginBlock: "25px" }}>
              <div
                className={`${styles.field_container} ${styles.media_container}`}
              >
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  Media
                  <Info
                    text="Recommended Size is 1080px x 1080px"
                    containerStyle={{ height: "1.1rem", width: "1.1rem" }}
                  />
                </h3>
                <ImageFolder
                  onSave={() => {}}
                  selectMultiple={false}
                  alreadySelected={[]}
                  media={eventDetails?.mediaUrl}
                  numberOfImagesPerRow={3}
                  updateMedia={(value: any) => {
                    setEventDetails((prev: any) => ({
                      ...prev,
                      mediaUrl: value,
                    }));
                  }}
                  text="Accepts Images and Videos"
                />
              </div>
              <div className="card">
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  Event Options
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                    // border: "1px solid red",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingBlock: "1rem",
                      borderBottom: "1px solid rgba(195, 195, 192,0.5)",
                    }}
                  >
                    <p>Tickets</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        fontSize: "1.05rem",
                      }}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        {eventDetails?.ticketPrice || "Free"}
                      </span>
                      <FaEdit onClick={() => setShowPrice(true)} />
                    </div>
                  </div>
                  {showPrice && (
                    <>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <input
                          type="number"
                          value={eventDetails?.ticketPrice}
                          name=""
                          id=""
                          style={{
                            height: "30px",
                            borderRadius: "5px",
                            paddingInline: "10px",
                          }}
                          onChange={(e: any) => {
                            setEventDetails((prev: any) => ({
                              ...prev,
                              ticketPrice: e.target.value,
                            }));
                          }}
                        />
                        <button
                          style={{
                            height: "30px",
                            borderRadius: "5px",
                            backgroundColor: "#397ee6",
                            border: "none",
                            color: "white",
                            paddingInline: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowPrice(false)}
                        >
                          Set Price
                        </button>
                        <button
                          style={{
                            height: "30px",
                            borderRadius: "5px",
                            backgroundColor: "grey",
                            border: "none",
                            color: "white",
                            paddingInline: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowPrice(false);
                            setEventDetails((prev: any) => ({
                              ...prev,
                              ticketPrice: 0,
                            }));
                          }}
                        >
                          Remove Price
                        </button>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingBlock: "1rem",
                      borderBottom: "1px solid rgba(195, 195, 192,0.5)",
                    }}
                  >
                    <label>Require Approval</label>
                    <ToggleSwitch
                      value={eventDetails?.approval}
                      onClick={() => {
                        setEventDetails((prev: any) => ({
                          ...prev,
                          approval: !prev.approval,
                        }));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingBlock: "1rem",
                    }}
                  >
                    <p>Capacity</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        fontSize: "1.05rem",
                      }}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        {eventDetails?.capacity || "Unlimited"}
                      </span>
                      <FaEdit onClick={() => setShowCapacity(true)} />
                    </div>
                  </div>
                  {showCapacity && (
                    <>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <input
                          type="number"
                          value={eventDetails?.capacity}
                          name=""
                          id=""
                          style={{
                            height: "30px",
                            borderRadius: "5px",
                            paddingInline: "10px",
                          }}
                          onChange={(e: any) => {
                            setEventDetails((prev: any) => ({
                              ...prev,
                              capacity: e.target.value,
                            }));
                          }}
                        />
                        <button
                          style={{
                            height: "30px",
                            borderRadius: "5px",
                            backgroundColor: "#397ee6",
                            border: "none",
                            color: "white",
                            paddingInline: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowCapacity(false)}
                        >
                          Set Limit
                        </button>
                        <button
                          style={{
                            height: "30px",
                            borderRadius: "5px",
                            backgroundColor: "grey",
                            border: "none",
                            color: "white",
                            paddingInline: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowCapacity(false);
                            setEventDetails((prev: any) => ({
                              ...prev,
                              capacity: 0,
                            }));
                          }}
                        >
                          Remove Limit
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginTop: "20px",
              }}
            >
              {langObj.description[lang]}
            </p>
            <TextEditor
              content={eventDetails?.description}
              handleChange={(value: any) => {
                setEventDetails((prev: any) => ({
                  ...prev,
                  description: value,
                }));
              }}
            />
            <div className="card grid" style={{ marginTop: "25px" }}>
              <h3>Meta Data</h3>
              <InputField
                isLabel={true}
                lable={"Meta Title"}
                value={metaData.metaTitle}
                type="text"
                onChange={(e: any) => {
                  setMetaData((prev: any) => ({
                    ...prev,
                    metaTitle: e.target.value,
                  }));
                }}
              />
              <InputField
                isLabel={true}
                lable={"Meta Description"}
                value={metaData?.metaDescription}
                onChange={(e: any) => {
                  setMetaData((prev: any) => ({
                    ...prev,
                    metaDescription: e.target.value,
                  }));
                }}
                type="textarea"
              />
            </div>
          </>
        </ContentBody>
      </Content>
    </>
  );
};

export default CreateEvent;
