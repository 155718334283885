import React, { useEffect, useState } from "react";
import styles from "./viewEvents.module.css";
import EventDetails from "./eventDetails/eventDetail";
import EventUsers from "./eventDetails/users";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";

const EventsView = () => {
  const [tab, setTab] = useState("view");
  const params = useParams();
  const [eventDetail, setEventDetails] = useState<any>();

  const getData = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}service/` + params.id,
      method: "get",
    })
      .then((res) => {
        // console.log(res);
        setEventDetails(res.data.service);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Content>
      <ContentHead title={eventDetail?.title} />
      <div className={styles.event_main}>
        <div className={styles.tabs_section}>
          <div
            className={tab === "view" ? styles.tab_selected : ""}
            onClick={() => setTab("view")}
          >
            Overview
          </div>
          <div
            className={tab === "users" ? styles.tab_selected : ""}
            onClick={() => setTab("users")}
          >
            Users
          </div>
        </div>
        {tab === "view" ? <EventDetails data={eventDetail} /> : <EventUsers />}
      </div>
    </Content>
  );
};

export default EventsView;
